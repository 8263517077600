import React, { useState } from 'react'
import { PiSignIn } from "react-icons/pi";
import { Link } from 'react-router-dom';
import { FaBars } from "react-icons/fa6";
import { IoMdClose } from "react-icons/io";




const Header = ({ logout, user }) => {
    const [openMenu, setOpenMenu] = useState(null)

    return (
        <div className="header">
            <div className="header__container container">
                <div className="header__logo">
                    MOKANDA
                </div>
                <div className="header__right">
                    <ul className={openMenu ? "openMOff" : "header__list"}>
                        <div
                            onClick={() => setOpenMenu(null)}
                            className="closeMenu">
                            <IoMdClose className='closeMenu__icon' />
                        </div>
                        <Link onClick={() => setOpenMenu(null)} to='/home' className="header__item">ACCUEIL</Link>
                        <Link onClick={() => setOpenMenu(null)} to='/publication-de-mariage' className="header__item">MARIAGES PUBLIÉS
                        </Link>
                        {/* <Link to='/red'>Red</Link> */}
                        <Link onClick={() => setOpenMenu(null)} to='/contact' className="header__item">CONTACTEZ-NOUS
                        </Link>
                        <Link onClick={() => setOpenMenu(null)} to='/faq' className="header__item">FAQ</Link>
                        {/* <Link to='/document' className="header__item">Document</Link> */}
                        {/* {user ? <Link onClick={() => setOpenMenu(null)} to='/formulaire-de-lien-parente' className="header__item"> formulaires</Link> : false} */}
                        <Link onClick={() => setOpenMenu(null)} to='/forms' className="header__item">Liste des formulaires</Link>
                        {/* {user ? <Link onClick={() => setOpenMenu(null)} to='/attestations' className="header__item">Vos Attestations</Link> : false} */}
                        <Link to='/liste-de-tous-les-documents' className="header__item">Liste de Documents</Link>
                        {user ? <div className="header__user header__user__list">
                            <h2 className="header__user__title">{user.name}</h2>
                            <h3
                                onClick={logout}
                                className="header__user__btn">Déconnexion</h3>
                        </div> : null}
                    </ul>
                    {user ?
                        null
                        : <Link to='/' className="header__button">
                            <PiSignIn className='header__icon' />
                            <span>Se connecter</span>
                        </Link>
                    }
                    {user ?
                        <div className="header__user">
                            <h2 className="header__user__title">{user.name}</h2>
                            <h3
                                onClick={logout}
                                className="header__user__btn">Déconnexion</h3>
                        </div>
                        : null
                    }
                    <div
                        onClick={() => setOpenMenu(true)}
                        className="header__openMenu">
                        <FaBars className='header__openMenu__icon' />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Header
