import axios from 'axios'



const apiCreateLinkParente = '/api/lien'
// const apiCreateLinkParente = 'http://localhost:3001/UserLienDeParente'

// const apiResidence = 'http://localhost:3001/Residence';
const apiResidence = '/api/attestionResid';

const apiUser = '/api/users';

let token = null;
const setToken = newToken => {
    token = `Bearer ${newToken}`;
    setupAxiosInterceptors(); // Assurez-vous d'appeler cette fonction chaque fois que vous modifiez le token
};

const setupAxiosInterceptors = () => {
    axios.interceptors.request.use(request => {
        if (token) {
            request.headers.Authorization = token;
        }
        return request;
    });
};

// Assurez-vous d'appeler setupAxiosInterceptors au moins une fois pour configurer l'intercepteur
setupAxiosInterceptors();



const CreateLienDeParente = objects => {
    const request = axios.post(apiCreateLinkParente, objects)
    return request.then(response => response.data)
}


const getAllLienDeParente = () => {
    const request = axios.get(apiCreateLinkParente)
    return request.then(response => response.data)
}


const updateLienDeParente = (objects, id) => {
    const request = axios.put(`${apiCreateLinkParente}/${id}`, objects)
    return request.then(response => response)
}



/// Creation d'un utilisateur 
const createUser = objects => {
     const request = axios.post(apiUser, objects)
     return request.then(response => response.data)
}


const getUser = () => {
    const request = axios.get(apiUser)
    return request.then(response => response.data)
}



// POUR L'ATTESTATION DE RESIDENCE
const getResidence = () => {
     const request = axios.get(apiResidence)
     return request.then(response => response.data)
}


const createResidence = objects => {
    const request = axios.post(apiResidence, objects)
    return request.then(response => response.data)
}





export default {
    setToken,
    getUser,
    createUser,
    CreateLienDeParente,
    getAllLienDeParente,
    updateLienDeParente,

    getResidence,
    createResidence,
}