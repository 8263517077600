import React from 'react'
import { Link } from 'react-router-dom'
import logo from '../Assets/fav.png'
import Loading from './Loading'

const Sign = ({ addUser,addLogin, name, setName, setUsers, users,password, setPassword, responseIs}) => {
  return (
    <div className="sign">
          { responseIs && <Loading /> }
        <div className="sign__container container">
            <form onSubmit={addLogin} className="sign__form">
                 <div className="sign__image">
                    <img src={logo} alt="" className="sign__img" />
                </div>
                <div className="sign__text">Connectez-vous à MOKANDA</div>
                <div className="sign__app">
                     <div className="sign__cards">
                        <div className="sign__card">
                            <div className="sign__spa">Login</div>
                            <input value={name} 
                             onChange={(e) => setName(e.target.value)}
                             type="text" placeholder='Entrer le login' className="sign__input" />
                        </div>
                        <div className="sign__card">
                            <div className="sign__spa">Mot de passe</div>
                            <input value={password} 
                             onChange={(e) => setPassword(e.target.value)}
                             type="text" placeholder='Entrer le mot de passe' className="sign__input" />
                        </div>
                     </div>
                     <div className="sign__elements">
                        <input type="checkbox" className="sign__inputchecbock" />
                        <div className="sign__content">
                            <span>Se souvenir de moi</span> <Link className="sign__link">Mot de passe oublié</Link>
                        </div>
                     </div>
                     <button className="sign__button">Se connecter</button>
                </div>
                <div className="sign__bottom">
                     <span>Nouveau sur MOKANDA </span> <Link className='sign__bottom__link' to='/'>inscrivez-vous</Link>
                </div>

            </form>
        </div>
        <div className="scan__footer sign__footer">
         © 2020-2024 <a href="https://www.afriquedigitalsk.com/"> Afrique Digitale </a> - Système de gestion des communes
         </div>
    </div>
  )
}

export default Sign
