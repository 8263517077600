import React, { useEffect, useState } from 'react'
import Header from './Components/Header'
import Home from './Components/Home'
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom'
import Production from './Components/Production'
import FormLienDeParente from './Components/FormLienDeParente'
import ListLienDeParente from './Components/ListLienDeParente'
import Base from './Api/Base'
import Sign from './Components/Sign'
import Loading from './Components/Loading'
import auth from './Api/auth'
import ListForm from './Components/ListForm'
import FormAttestationDeResidence from './Components/FormAttestationDeResidence'
import ListAllDocuments from './Components/ListAllDocuments'
import AttestationResidence from './Components/AttestationResidence'


const App = () => {

  const navigate = useNavigate()
  // Create User
  const [user, setUser] = useState(null)
  const [users, setUsers] = useState([])
  const [password, setPassword] = useState('')
  const [name, setName] = useState('')

  const [linkParente, setLinkParente] = useState([])
  const [commune, setCommune] = useState('')
  const [numPage, setNumPage] = useState('')
  const [numTimbre, setNumTimbre] = useState('')
  // Deuxieme Lot         
  const [nameOfficer, setNameOfficer] = useState('')
  const [nameCommuneOfficer, setNameCommuneOfficer] = useState('')
  // Troisieme lot
  const [sexePreneur, setSexePreneur] = useState('')
  const [namePreneur, setNamePreneur] = useState('')
  const [placeofBirthPreneur, setPlaceOfBirthPreneur] = useState(' ')
  const [dateBornPreneur, setDateBornPreneur] = useState('')
  const [professionPreneur, setProfessionPreneur] = useState('')
  //  Quatrieme lot
  const [nameFatherPreneur, setNameFatherPreneur] = useState('')
  const [nameMotherPreneur, setNameMotherPreneur] = useState('')
  const [placeOfResidenceParentPreneur, setPlaceOfResidenceParentPreneur] = useState('')
  const [AvenueParentPreneur, setAvenueParentPreneur] = useState(' ')
  const [numParentPreneur, setNumParentPreneur] = useState('')
  const [quartierParentPreneur, setQuartierParentPreneur] = useState('')
  const [communeParentPreneur, setCommuneParentPreneur] = useState('')
  const [positionPreneur, setPositionPreneur] = useState('')
  //  Cinquieme lot
  const [positionFamillialePreneur, setPositionFamillialePreneur] = useState('')
  const [sexeEtudiant, setSexeEtudiant] = useState('')
  const [nameEtudiant, setNameEtudiant] = useState('')
  const [dateBornEtudiant, setDateBornEtudiant] = useState('')
  const [placeOfBirthEtudiant, setPlaceOfBirthEtudiant] = useState('')
  const [professionEtudiant, setProfessionEtudiant] = useState('')
  const [avenuEtudiant, setAvenuEtudiant] = useState('')
  const [numeroEtudiant, setNumeroEtudiant] = useState('')
  // Sixieme lot
  const [quartierEtudiant, setQuartierEtudiant] = useState('')
  const [communeEtudiant, setCommuneEtudiant] = useState('')
  const [nameFatherEtudiant, setNameFatherEtudiant] = useState('')
  const [nameMotherEtudiant, setNameMotherEtudiant] = useState('')
  // Septieme lot
  const [madeIn, setMadeIn] = useState('')
  const [dateMadeIn, setDateMadeIn] = useState('')


  // Pour L'ATTESTATION DE RESIDENCE
  const [linkResidence, setLinkResidence] = useState([])
  const [residCommune, setResidCommune] = useState('')
  const [residNumPage, setResidNumPage] = useState('')
  const [residNumTimbre, setResidNumTimbre] = useState('')
  const [nameBourgmestre, setNameBourgmestre] = useState('')
  const [residNamesEtudiant, setResidNamesEtudiant] = useState('')
  const [residCityBirthEtudiant, setResidCityBirthEtudiant] = useState('')
  const [residDateBirthEtudiant, setResidDateBirthEtudiant] = useState('')
  const [residPereEtudiant, setResidPereEtudiant] = useState('')
  const [residMereEtdudiant, setResidMereEtudaint] = useState('')
  const [residFemmeEtudiant, setResidFemmeEtudiant] = useState('')
  const [residAvenuEtudiant, setResidAvenuEtudiant] = useState('')
  const [residNumEtudiant, setNumEtudiant] = useState('')
  const [residQuartierEtudiant, setResidQuartierEtudiant] = useState('')
  const [residCommuneEtudiant, setResidCommuneEtudiant] = useState('')
  const [residProfession, setResidProfession] = useState('')
  const [residDateMadeIn, setResidDateMadeIn] = useState('')
  const [residCityMadeIn, setResidCityMadeIn] = useState('')
  const [residNumDossier, setNumDossier] = useState('')

  const [residDateIdentifie, setResidDateIdentifie] = useState('')
  const [residCityIdentifie, setResidCityIdentifie] = useState('')


  // Pour les reponses 
  const [responseIs, setResponseIs] = useState(null)
  const [resp , setResp] = useState(false)


  const resetForm = () => {
    setCommune('')
    setNumPage('')
    setNumTimbre('')
    setNameOfficer('')
    setNameCommuneOfficer('')
    setSexePreneur('')
    setNamePreneur('')
    setPlaceOfBirthPreneur('')
    setDateBornPreneur('')
    setProfessionPreneur('')
    setNameFatherPreneur('')
    setNameMotherPreneur('')
    setPlaceOfResidenceParentPreneur('')
    setAvenueParentPreneur('')
    setNumParentPreneur('')
    setQuartierParentPreneur('')
    setCommuneParentPreneur('')
    setPositionPreneur('')
    setPositionFamillialePreneur('')
    setSexeEtudiant('')
    setNameEtudiant('')
    setDateBornEtudiant('')
    setPlaceOfBirthEtudiant('')
    setProfessionEtudiant('')
    setAvenuEtudiant('')
    setNumeroEtudiant('')
    setQuartierEtudiant('')
    setCommuneEtudiant('')
    setNameFatherEtudiant('')
    setNameMotherEtudiant('')
    setMadeIn('')
    setDateMadeIn('')
  }


  // Create addUser
  const addUser = (e) => {
    e.preventDefault()
    const addForm = {
      name: name,
      password: password,
    }
    setResponseIs(true)

    Base.createUser(addForm)
      .then(response => {
        users.concat(response.data)
        setResponseIs('Utilisateur bien ajouté')
      })
      .finally((suc) => {
        setTimeout(() => {
          setResponseIs(false)
        }, 4000)
      })

    setName('')
    setPassword('')
  }


  // Login User 
  const addLogin = async (e) => {
    e.preventDefault()

    try {
      const user = await auth.login({ name, password })
      window.localStorage.setItem('userIn', JSON.stringify(user));
      setUser(user);
      Base.setToken(user.token);
      setResponseIs(true)
      setTimeout(() => {
        setResponseIs(false)
        navigate('/home')
      }, 3000)
      setName('')
      setPassword('')
    } catch (error) {
      console.log('error')
    }
  }


  // Déconnecter l'utilisateur avec le bouton
  const logout = () => {
    window.localStorage.removeItem('userIn');
    setUser(null);
    Base.setToken(null);
    navigate('/')
  };


  // Afficher tout ca 
  useEffect(() => {
    Base.getAllLienDeParente()
      .then(response => setLinkParente(response))
  }, [])

  useEffect(() => {
    Base.getUser()
      .then(response => setUsers(response))
  }, [])


  useEffect(() => {
    const storedUser = window.localStorage.getItem('userIn');
    const storedToken = window.localStorage.getItem('token');
    if (storedUser && storedToken) {
      setUser(JSON.parse(storedUser));
      Base.setToken(storedToken);
    }
  }, []);

  useEffect(() => {
    Base.getResidence()
    .then(response => setLinkResidence(response))
  },[])


  // Create the addLinkParente
  const addLinkParente = async (e) => {
    e.preventDefault()

    const functLinkParente = {
      commune: commune,
      numPage: numPage,
      numTimbre: numTimbre,
      nameOfficer: nameOfficer,
      nameCommuneOfficer: nameCommuneOfficer,
      sexePreneur: sexePreneur,
      namePreneur: namePreneur,
      placeofBirthPreneur: placeofBirthPreneur,
      dateBornPreneur: dateBornPreneur,
      professionPreneur: professionPreneur,
      nameFatherPreneur: nameFatherPreneur,
      nameMotherPreneur: nameMotherPreneur,
      placeOfResidenceParentPreneur: placeOfResidenceParentPreneur,
      AvenueParentPreneur: AvenueParentPreneur,
      numParentPreneur: numParentPreneur,
      quartierParentPreneur: quartierParentPreneur,
      communeParentPreneur: communeParentPreneur,
      positionPreneur: positionPreneur,
      positionFamillialePreneur: positionFamillialePreneur,
      sexeEtudiant: sexeEtudiant,
      nameEtudiant: nameEtudiant,
      dateBornEtudiant: dateBornEtudiant,
      placeOfBirthEtudiant: placeOfBirthEtudiant,
      professionEtudiant: professionEtudiant,
      avenuEtudiant: avenuEtudiant,
      numeroEtudiant: numeroEtudiant,
      quartierEtudiant: quartierEtudiant,
      communeEtudiant: communeEtudiant,
      nameFatherEtudiant: nameFatherEtudiant,
      nameMotherEtudiant: nameMotherEtudiant,
      madeIn: madeIn,
      dateMadeIn: dateMadeIn,
    }
    Base.CreateLienDeParente(functLinkParente)
      .then(response => {
        // Ajouter le nouvel élément à linkParente
        setLinkParente(prevState => [...prevState, response]);

        // Réinitialisation du formulaire
        resetForm();

        // Affichage d'un message de réussite temporaire
        setResp(true)
       setTimeout(() => {
         setResp(false)
         navigate('/liste-de-tous-les-documents')
       }, 3000)
      })
      .catch(error => {
        console.log('Une erreur a eu lieu ', error);
        // Gestion de l'erreur...
      });
  }


  const addResidence = (e) => {
       e.preventDefault()

       const residenceInfo = {
        residCommune: residCommune,
        residNumPage: residNumPage,
        residNumTimbre: residNumTimbre,
        nameBourgmestre: nameBourgmestre,
        residNamesEtudiant: residNamesEtudiant,
        residCityBirthEtudiant: residCityBirthEtudiant,
        residDateBirthEtudiant: residDateBirthEtudiant,
        residPereEtudiant: residPereEtudiant,
        residMereEtdudiant: residMereEtdudiant,
        residFemmeEtudiant: residFemmeEtudiant,
        residAvenuEtudiant: residAvenuEtudiant,
        residNumEtudiant: residNumEtudiant,
        residQuartierEtudiant: residQuartierEtudiant,
        residCommuneEtudiant: residCommuneEtudiant,
        residProfession: residProfession,
        residDateMadeIn: residDateMadeIn,
        residNumDossier: residNumDossier,
        residCityMadeIn: residCityMadeIn,
        residDateIdentifie: residDateIdentifie,
        residCityIdentifie: residCityIdentifie,
       }

       Base.createResidence(residenceInfo).then(response => response.data)
       setResp(true)
       setTimeout(() => {
         setResp(false)
         navigate('/liste-de-tous-les-documents')
       }, 3000)
  }

  return (
    <div className='app'>
      <div className='app__container'>
        <div className='app__header'>
          <Header logout={logout} user={user} />
        </div>
        <div className='app__body'>
          <Routes>
            <Route path='/' element={<Sign
              addUser={addUser}
              name={name}
              setName={setName}
              setUsers={setUsers}
              users={users}
              setPassword={setPassword}
              password={password}
              responseIs={responseIs}
              addLogin={addLogin}
            />}
            />
            <Route path='/home' element={<Home />} />
            <Route path='/scan/:id' element={<Production user={user} linkParente={linkParente} />} />
            {user ?
              <Route path='/formulaire-de-lien-parente' element={<FormLienDeParente
                user={user}
                resp={resp}
                addLinkParente={addLinkParente}
                responseIs={responseIs}
                linkParente={linkParente}
                commune={commune}
                numPage={numPage}
                numTimbre={numTimbre}
                nameOfficer={nameOfficer}
                nameCommuneOfficer={nameCommuneOfficer}
                sexePreneur={sexePreneur}
                namePreneur={namePreneur}
                placeofBirthPreneur={placeofBirthPreneur}
                dateBornPreneur={dateBornPreneur}
                professionPreneur={professionPreneur}
                nameFatherPreneur={nameFatherPreneur}
                nameMotherPreneur={nameMotherPreneur}
                placeOfResidenceParentPreneur={placeOfResidenceParentPreneur}
                AvenueParentPreneur={AvenueParentPreneur}
                numParentPreneur={numParentPreneur}
                quartierParentPreneur={quartierParentPreneur}
                communeParentPreneur={communeParentPreneur}
                positionPreneur={positionPreneur}
                positionFamillialePreneur={positionFamillialePreneur}
                sexeEtudiant={sexeEtudiant}
                nameEtudiant={nameEtudiant}
                dateBornEtudiant={dateBornEtudiant}
                placeOfBirthEtudiant={placeOfBirthEtudiant}
                professionEtudiant={professionEtudiant}
                avenuEtudiant={avenuEtudiant}
                numeroEtudiant={numeroEtudiant}
                quartierEtudiant={quartierEtudiant}
                communeEtudiant={communeEtudiant}
                nameFatherEtudiant={nameFatherEtudiant}
                nameMotherEtudiant={nameMotherEtudiant}
                madeIn={madeIn}
                dateMadeIn={dateMadeIn}

                setLinkParente={setLinkParente}
                setCommune={setCommune}
                setNumPage={setNumPage}
                setNumTimbre={setNumTimbre}
                setNameOfficer={setNameOfficer}
                setNameCommuneOfficer={setNameCommuneOfficer}
                setSexePreneur={setSexePreneur}
                setNamePreneur={setNamePreneur}
                setPlaceOfBirthPreneur={setPlaceOfBirthPreneur}
                setDateBornPreneur={setDateBornPreneur}
                setProfessionPreneur={setProfessionPreneur}
                setNameFatherPreneur={setNameFatherPreneur}
                setNameMotherPreneur={setNameMotherPreneur}
                setPlaceOfResidenceParentPreneur={setPlaceOfResidenceParentPreneur}
                setAvenueParentPreneur={setAvenueParentPreneur}
                setNumParentPreneur={setNumParentPreneur}
                setQuartierParentPreneur={setQuartierParentPreneur}
                setCommuneParentPreneur={setCommuneParentPreneur}
                setPositionPreneur={setPositionPreneur}
                setPositionFamillialePreneur={setPositionFamillialePreneur}
                setSexeEtudiant={setSexeEtudiant}
                setNameEtudiant={setNameEtudiant}
                setDateBornEtudiant={setDateBornEtudiant}
                setPlaceOfBirthEtudiant={setPlaceOfBirthEtudiant}
                setProfessionEtudiant={setProfessionEtudiant}
                setAvenuEtudiant={setAvenuEtudiant}
                setNumeroEtudiant={setNumeroEtudiant}
                setQuartierEtudiant={setQuartierEtudiant}
                setCommuneEtudiant={setCommuneEtudiant}
                setNameFatherEtudiant={setNameFatherEtudiant}
                setNameMotherEtudiant={setNameMotherEtudiant}
                setMadeIn={setMadeIn}
                setDateMadeIn={setDateMadeIn}
              />} />
              : false}
            <Route path='/attestation-de-residence'
              element=
              {<FormAttestationDeResidence
                residDateIdentifie={residDateIdentifie}
                setResidDateIdentifie={setResidDateIdentifie}
                residCityIdentifie={residCityIdentifie}
                setResidCityIdentifie={setResidCityIdentifie}
               resp={resp}
              addResidence={addResidence}
               residCityMadeIn={residCityMadeIn}
                linkResidence={linkResidence}
                residCommune={residCommune}
                residNumPage={residNumPage}
                residNumTimbre={residNumTimbre}
                nameBourgmestre={nameBourgmestre}
                residNamesEtudiant={residNamesEtudiant}
                residCityBirthEtudiant={residCityBirthEtudiant}
                residDateBirthEtudiant={residDateBirthEtudiant}
                residPereEtudiant={residPereEtudiant}
                residMereEtdudiant={residMereEtdudiant}
                residFemmeEtudiant={residFemmeEtudiant}
                residAvenuEtudiant={residAvenuEtudiant}
                residNumEtudiant={residNumEtudiant}
                residQuartierEtudiant={residQuartierEtudiant}
                residCommuneEtudiant={residCommuneEtudiant}
                residProfession={residProfession}
                residDateMadeIn={residDateMadeIn}
                residNumDossier={residNumDossier}
                setResidCityMadeIn={setResidCityMadeIn}
                setLinkResidence={setLinkResidence}
                setResidCommune={setResidCommune}
                setResidNumPage={setResidNumPage}
                setResidNumTimbre={setResidNumTimbre}
                setNameBourgmestre={setNameBourgmestre}
                setResidNamesEtudiant={setResidNamesEtudiant}
                setResidCityBirthEtudiant={setResidCityBirthEtudiant}
                setResidDateBirthEtudiant={setResidDateBirthEtudiant}
                setResidPereEtudiant={setResidPereEtudiant}
                setResidMereEtudaint={setResidMereEtudaint}
                setResidFemmeEtudiant={setResidFemmeEtudiant}
                setResidAvenuEtudiant={setResidAvenuEtudiant}
                setNumEtudiant={setNumEtudiant}
                setResidQuartierEtudiant={setResidQuartierEtudiant}
                setResidCommuneEtudiant={setResidCommuneEtudiant}
                setResidProfession={setResidProfession}
                setResidDateMadeIn={setResidDateMadeIn}
                setNumDossier={setNumDossier}
              />}
            />
            {/* {user ? <Route path='/attestations' element={<ListLienDeParente linkParente={linkParente} />} /> : false} */}
            <Route path='/forms' element={<ListForm />} />
            <Route path='/liste-de-tous-les-documents' element={<ListAllDocuments
              linkParente={linkParente}
              addResidence={addResidence}
              linkResidence={linkResidence}
            />}
            />
            <Route path='/scans/:id' element={<AttestationResidence
             linkResidence={linkResidence}
             
            />} />
          </Routes>
        </div>
      </div>
    </div>
  )
}

export default App
