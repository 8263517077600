import React from 'react'
import { IoEyeSharp } from "react-icons/io5";
import { Link } from 'react-router-dom'

const ListLienDeParente = ({ linkParente }) => {
    return (
        <div className="listAttRes">
        <div className="listAttRes__container container">
             <h2 className="listAttRes__title">Liste de lien de Parenté</h2>
             <div className="listAttRes__cards">
               {linkParente.map(item => 
                  <div className="listAttRes__card">
                  <h2 className="listAttRes__card__titl">Attestation de Lien de Parenté</h2>
                  <div className="listAttRes__names">
                      <div className="listAttRes__names__str">Nom du demandeur : </div>
                      <span className="listAttRes__names__name">{item.nameEtudiant}</span>
                  </div>
                  <Link to={`/scan/${item.id}`} className="listAttRes__btn">
                      <IoEyeSharp className='listAttRes__btn__icon' />
                      <div className="listAttRes__btn__span">Ouvrir l'Attestation</div>
                  </Link>
              </div>
               )}
             </div>
        </div>
    </div>
    )
}

export default ListLienDeParente
