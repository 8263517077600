import React from 'react'
import { FaDownload } from "react-icons/fa";


const Banner = () => {
  return (
    <div className="banner">
        <div className="banner__container container">
            <h2 className="banner__title">Les documents les plus produits</h2>
            <button className="banner__button">
                <span>Guide d'utilisation</span>
                <FaDownload className='banner__icons' />
            </button>
            <div className="banner__list">
                <div className="banner__item">
                    <div className="banner__image">
                    <svg className='banner__icon' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path className='banner__icon' d="M12.0004 12C9.23902 12 7.00045 9.76142 7.00045 7C7.00045 4.23858 9.23902 2 12.0004 2C14.7619 2 17.0004 4.23858 17.0004 7C17.0004 9.76142 14.7619 12 12.0004 12ZM18.0004 17V22H16.0004V17C16.0004 12.5487 18.6444 8.71498 22.4475 6.98352L23.2753 8.8047C20.1637 10.2213 18.0004 13.3581 18.0004 17ZM8.00045 17V22H6.00045V17C6.00045 13.3581 3.83723 10.2213 0.725586 8.8047L1.55339 6.98352C5.35651 8.71498 8.00045 12.5487 8.00045 17Z"></path></svg>
                    </div>
                    <div className="banner__text">

                        Attestation de naissance
                    </div>
                </div>
                <div className="banner__item">
                    <div className="banner__image">
                    <svg className='banner__icon' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path className='banner__icon' d="M10 19.748V16.4C10 15.1174 10.9948 14.1076 12.4667 13.5321C11.5431 13.188 10.5435 13 9.5 13C7.61013 13 5.86432 13.6168 4.45286 14.66C5.33199 17.1544 7.41273 19.082 10 19.748ZM18.8794 16.0859C18.4862 15.5526 17.1708 15 15.5 15C13.4939 15 12 15.7967 12 16.4V20C14.9255 20 17.4843 18.4296 18.8794 16.0859ZM9.55 11.5C10.7926 11.5 11.8 10.4926 11.8 9.25C11.8 8.00736 10.7926 7 9.55 7C8.30736 7 7.3 8.00736 7.3 9.25C7.3 10.4926 8.30736 11.5 9.55 11.5ZM15.5 12.5C16.6046 12.5 17.5 11.6046 17.5 10.5C17.5 9.39543 16.6046 8.5 15.5 8.5C14.3954 8.5 13.5 9.39543 13.5 10.5C13.5 11.6046 14.3954 12.5 15.5 12.5ZM12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22Z"></path></svg>                              
                    </div>
                    <div className="banner__text">

Attestation de naissance
Certificat de bonne vie et mœurs
                    </div>
                </div>
                <div className="banner__item">
                    <div className="banner__image">
                    <svg className='banner__icon' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path className='banner__icon' d="M20 20C20 20.5523 19.5523 21 19 21H5C4.44772 21 4 20.5523 4 20V11L1 11L11.3273 1.6115C11.7087 1.26475 12.2913 1.26475 12.6727 1.6115L23 11L20 11V20ZM11 13V19H13V13H11Z"></path></svg>
                    </div>
                    <div className="banner__text">
                    Attestation de residence
                    </div>
                </div>
                <div className="banner__item">
                    <div className="banner__image">
                    <svg className='banner__icon' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path className='banner__icon' d="M7 11C4.51472 11 2.5 8.98528 2.5 6.5C2.5 4.01472 4.51472 2 7 2C9.48528 2 11.5 4.01472 11.5 6.5C11.5 8.98528 9.48528 11 7 11ZM17.5 15C15.2909 15 13.5 13.2091 13.5 11C13.5 8.79086 15.2909 7 17.5 7C19.7091 7 21.5 8.79086 21.5 11C21.5 13.2091 19.7091 15 17.5 15ZM17.5 16C19.9853 16 22 18.0147 22 20.5V21H13V20.5C13 18.0147 15.0147 16 17.5 16ZM7 12C9.76142 12 12 14.2386 12 17V21H2V17C2 14.2386 4.23858 12 7 12Z"></path></svg>
                    </div>
                    <div className="banner__text">
                    Attestation de composition de famille
                    </div>
                </div>
            </div>
            <h3 className="banner__sub-title">Accédez aux services communaux de n'importe où de manière simple et efficace</h3>
            <div className="banner__sub-content">MOKANDA réduit la distance entre l'administration et ses administrés.</div>
            <ul className="banner__list-ol">
                <li className="banner__item-ol">
                     Demande en ligne des documents
                </li>
                <li className="banner__item-ol">
                     Paiement électronique des services
                </li>
                <li className="banner__item-ol">
                     Suivi du dossier à distance.
                </li>
                <li className="banner__item-ol">
                     Traçabilité des documents
                </li>
            </ul>
        </div>
    </div>
  )
}

export default Banner
