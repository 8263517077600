import React from "react";
import ReactDOM from 'react-dom/client'
import { BrowserRouter as Router } from "react-router-dom";
import App from './App'
import './Themes/Styles.scss' 


ReactDOM.createRoot(document.getElementById('root'))
.render(
  <Router>
    <App />
  </Router>
)