import React from 'react'
import { Link } from 'react-router-dom'

const Redirect = () => {
  return (
    <div className="redirect">
        <div className="redirect__container container">
            <h2 className="redirect__title">Vous n'etes pas connectez</h2>
            <div className="redirect__content">
                Veuillez vous connecter sur ce <Link to='/' className="redirect__link">Ici.</Link>
            </div>
        </div>
    </div>
  )
}

export default Redirect
