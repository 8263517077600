import React from 'react'
import { useParams } from 'react-router-dom'

const AttestationResidence = ({ linkResidence }) => {

   const ids = useParams().id
   const residAtt = linkResidence.find(item => item.id === ids)
   
   console.log(residAtt)

   if (!residAtt) {
     return <div className="formAttestationDeResidence">
       Pas de formulaire trouvé
     </div>
   }



  return (
    <div className="scan">
    <div className="scan__container container">
       <div className="scan__header">
          <h2 className="scan__title">Authentification des documents  </h2>
          <div className="scan__sub__title">Resultat du scan </div>
       </div>
       <div className="scan__banner">
          <div className="scan__check">
          <svg className='scan__check__icon' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="red"><path d="M9.9997 15.1709L19.1921 5.97852L20.6063 7.39273L9.9997 17.9993L3.63574 11.6354L5.04996 10.2212L9.9997 15.1709Z"></path></svg>
          <div className="scan__banner__content"> Le document scanné a été retrouvé</div>
          </div>
          <div className="scan__cross">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="red"><path d="M11.9997 10.5865L16.9495 5.63672L18.3637 7.05093L13.4139 12.0007L18.3637 16.9504L16.9495 18.3646L11.9997 13.4149L7.04996 18.3646L5.63574 16.9504L10.5855 12.0007L5.63574 7.05093L7.04996 5.63672L11.9997 10.5865Z"></path></svg>
          </div>
       </div>
       <div className="scan__card">
           <div className="scan__card__header">
              <span>DOCUMENT</span>
           </div>
           <div className="scan__card__body">
               <div className="scan__card__body__qrcode"> 
               {residAtt.qrCodeLine && <img src={residAtt.qrCodeLine} alt="QR Code" className="scan__card__body__qrcode__img" />}
                  <div className="scan__card__body__qrcode__list">
                      <div className="scan__card__body__qrcode__item blue">P.No: {residAtt.residNumPage}</div> /
                      <div className="scan__card__body__qrcode__item blue">T.No {residAtt.residNumTimbre}</div>
                  </div>
                </div>
                  <div className="scan__card__body__details">
                    <div className="scan__card__body__details__city">Ville de <span className='blue'>Kinshasa</span></div>
                    <div className="scan__card__body__details__commune">Commune de <span className='blue'>{residAtt.residCommune}</span></div>
                    <div className="scan__card__body__details__service"><span className='blue'>Service de Population</span></div>
                  </div>
                  <div className="scan__card__body__views">
                      <h3 className="scan__card__body__views__title">ATTESTATION DE RESIDENCE N°.....</h3>
                      <div className="scan__card__body__views__contents">
                         <div className="scan__card__body__views__content">
                            Je soussigné, <span className='blue'>{residAtt.nameBourgmestre}</span>, Bourgmestre de la Commune de <span className='blue'>{residAtt.residCommune}</span>  atteste par la présente que <span className='blue'>{residAtt.residNamesEtudiant}</span>, né (e) à <span className='blue'>{residAtt.residCityBirthEtudiant}</span> le <span className='blue'>{residAtt.residDateBirthEtudiant}</span>,
 <span className='blue'> fils (fille) </span> de <span className='blue'>{residAtt.residPereEtudiant}</span> et de <span className='blue'>{residAtt.residMereEtdudiant}, est marié à </span> <span className='blue'>{residAtt.residFemmeEtudiant}</span>, résidant sur l'avenue <span className='blue'>{residAtt.residAvenuEtudiant}</span> <span className='blue'>n°</span><span className='blue'>{residAtt.residNumEtudiant}</span>
                           <span className='blue'> dans le quartier {residAtt.residQuartierEtudiant}</span> <span className='blue'> dans la commune de {residAtt.residCommuneEtudiant}</span>, de nationalité <span className='blue'>Congolaise</span>,  de profession<span className='blue'> {residAtt.residProfession}</span>, a été identifié(e) dans la commune de <span className='blue'>{residAtt.residCommune}</span>  à <strong className='blue'>{residAtt.residCityIdentifie}</strong>, 
                           le <span className='blue'>{residAtt.residDateIdentifie}</span> sous <span className='blue'>N° {residAtt.residNumDossier}</span>.
                         </div>

                         <div className="scan__card__body__views__content blue">
                          Depuis lors aucune mention d'interruption de séjour á Kinshasa n'a été relevé dans ces pièces d'identités.
                         </div>
                      </div>
                      <div className="scan__card__body__views__dates">
                         <div className="scan__card__body__views__dates__realisation">Fait à <span className='blue'>{residAtt.residCityMadeIn}</span>, 
                         le <span className='blue'>{residAtt.residDateMadeIn}</span></div>
                         <div className="scan__card__body__views__dates__sign">Le <span className='blue'>Bourgmestre</span></div>
                      </div>
                  </div>
               </div>
       </div>
    </div>
       <div className="scan__footer">
       © 2020-2024 <a href="https://www.afriquedigitalsk.com/"> Afrique Digitale </a> - Système de gestion des communes
       </div>
  </div>
  )
}

export default AttestationResidence
