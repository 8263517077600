import React from 'react'
import ListAttestionResidence from './ListAttestionResidence'
import ListLienDeParente from './ListLienDeParente'

const ListAllDocuments = ({ linkParente,addResidence,linkResidence }) => {
  return (
    <div className="listAllDoc">
        <div className="listAllDoc__container container">
             <h2 className="listAllDoc__title">Liste des tous vos Document</h2>
             <div className="listAllDoc__list">
                <ListAttestionResidence  linkResidence={linkResidence} addResidence={addResidence}/>
                <ListLienDeParente linkParente={linkParente} />
                {/* <ListAttestionResidence /> */}
             </div>
        </div>
    </div>
  )
}

export default ListAllDocuments
