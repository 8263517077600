import React from 'react'
import { useParams } from 'react-router-dom'
import Redirect from './Redirect'

const Production = ({ linkParente, user }) => {
   const ids = useParams().id
   const linkParentId =  linkParente.find(f => f.id === ids)
   
   if(!linkParentId) {
      return <div className="resource">Pas des resultat</div>
   }


  return (
    <div className="scan">
      <div className="scan__container container">
         <div className="scan__header">
            <h2 className="scan__title">Authentification des documents  </h2>
            <div className="scan__sub__title">Resultat du scan </div>
         </div>
         <div className="scan__banner">
            <div className="scan__check">
            <svg className='scan__check__icon' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="red"><path d="M9.9997 15.1709L19.1921 5.97852L20.6063 7.39273L9.9997 17.9993L3.63574 11.6354L5.04996 10.2212L9.9997 15.1709Z"></path></svg>
            <div className="scan__banner__content"> Le document scanné a été retrouvé</div>
            </div>
            <div className="scan__cross">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="red"><path d="M11.9997 10.5865L16.9495 5.63672L18.3637 7.05093L13.4139 12.0007L18.3637 16.9504L16.9495 18.3646L11.9997 13.4149L7.04996 18.3646L5.63574 16.9504L10.5855 12.0007L5.63574 7.05093L7.04996 5.63672L11.9997 10.5865Z"></path></svg>
            </div>
         </div>
         <div className="scan__card">
             <div className="scan__card__header">
                <span>DOCUMENT</span>
             </div>
             <div className="scan__card__body">
                 <div className="scan__card__body__qrcode"> 
                 {linkParentId.qrCodeLine && <img src={linkParentId.qrCodeLine} alt="QR Code" className="scan__card__body__qrcode__img" />}
                    <div className="scan__card__body__qrcode__list">
                        <div className="scan__card__body__qrcode__item blue">P.No: {linkParentId.numPage}</div> /
                        <div className="scan__card__body__qrcode__item blue">T.No {linkParentId.numTimbre}</div>
                    </div>
                  </div>
                    <div className="scan__card__body__details">
                      <div className="scan__card__body__details__city">Ville de <span className='blue'>Kinshasa</span></div>
                      <div className="scan__card__body__details__commune">Commune de <span className='blue'>{linkParentId.commune}</span></div>
                      <div className="scan__card__body__details__service"><span className='blue'>Service de L'État Civil</span></div>
                    </div>
                    <div className="scan__card__body__views">
                        <h3 className="scan__card__body__views__title">ATTESTATION DE LIEN DE PARENTÉ N°.....</h3>
                        <div className="scan__card__body__views__contents">
                           <div className="scan__card__body__views__content">
                              Je soussigné, <span className='blue'>{linkParentId.nameOfficer}</span>, Officier de l'État Civil de la Commune de <span className='blue'>{linkParentId.commune}</span> à <span>Kinshasa</span>,
                              atteste par la présente que <span className='blue'>{linkParentId.sexePreneur}</span> <span className='blue'>{linkParentId.namePreneur}</span> né (e) à <span className='blue'>{linkParentId.placeofBirthPreneur}</span> le <span className='blue'>{linkParentId.dateBornPreneur}</span>,
                              de profession <span className='blue'>{linkParentId.professionPreneur},</span> <span className='blue'> de nationalité Congolaise, fils (fille) de </span> <span className='blue'>{linkParentId.nameFatherPreneur}</span> et de <span className='blue'>{linkParentId.nameMotherPreneur}</span>, résidant à <span className='blue'>{linkParentId.placeOfResidenceParentPreneur}</span> sur l'avenue <span className='blue'>{linkParentId.AvenueParentPreneur}</span> <span className='blue'>n°</span><span className='blue'>{linkParentId.numParentPreneur}</span>,
                              quartier <span className='blue'>{linkParentId.quartierParentPreneur}</span> dans la Commune de <span className='blue'>{linkParentId.communeParentPreneur}</span>, est le(la) <span className='blue'>{linkParentId.positionPreneur}</span> <span className='blue'>{linkParentId.positionFamillialePreneur}</span>  de <span className='blue'>{linkParentId.sexeEtudiant}</span> <span className='blue'>{linkParentId.nameEtudiant}</span> né (e) le <span className='blue'>{linkParentId.dateBornEtudiant}</span> à <span className='blue'>{linkParentId.placeOfBirthEtudiant}</span> de profession <span className='blue'>{linkParentId.professionEtudiant}</span>,
                              de nationalité Congolaise, résidant à <span className='blue'>Kinshasa</span> sur l'avenue de <span className='blue'>{linkParentId.avenuEtudiant}</span> n°<span className='blue'>{linkParentId.numeroEtudiant}</span>, quartier <span className='blue'>{linkParentId.quartierEtudiant}</span> dans la Commune de <span className='blue'>{linkParentId.communeEtudiant}</span>, fils(fille) de <span className='blue'>{linkParentId.nameFatherEtudiant}</span> et de <span className='blue'>{linkParentId.nameMotherEtudiant}</span>.
                           </div>

                           <div className="scan__card__body__views__content blue">
                              En foi de quoi, la présente est établie pour servir et faire valoir ce que de Droit.
                           </div>
                        </div>
                        <div className="scan__card__body__views__dates">
                           <div className="scan__card__body__views__dates__realisation">Fait à <span className='blue'>{linkParentId.madeIn}</span>, le <span className='blue'>{linkParentId.dateMadeIn}</span></div>
                           <div className="scan__card__body__views__dates__sign">L'Officier de l'État Civil</div>
                        </div>
                    </div>
                 </div>
         </div>
      </div>
         <div className="scan__footer">
         © 2020-2024 <a href="https://www.afriquedigitalsk.com/"> Afrique Digitale </a> - Système de gestion des communes
         </div>
    </div>
  )
}

export default Production
