import React from 'react'
import { IoEyeSharp } from "react-icons/io5";
import { Link, useParams } from 'react-router-dom';


const ListAttestionResidence = ({ addResidence,linkResidence }) => {

  return (
    <div className="listAttRes">
        <div className="listAttRes__container container">
             <h2 className="listAttRes__title">Liste des Attestations de Résidence</h2>
             <div className="listAttRes__cards">
                {linkResidence.map(item => 
                    <div className="listAttRes__card">
                    <h2 className="listAttRes__card__titl">Attestation de Résidence</h2>
                    <div className="listAttRes__names">
                        <div className="listAttRes__names__str">Nom du demandeur : </div>
                        <span className="listAttRes__names__name">{item.residNamesEtudiant}</span>
                    </div>
                    <Link to={`/scans/${item.id}`} className="listAttRes__btn">
                        <IoEyeSharp className='listAttRes__btn__icon' />
                        <div className="listAttRes__btn__span">Ouvrir l'Attestation</div>
                    </Link>
                </div>
                )}
             </div>
        </div>
    </div>
  )
}

export default ListAttestionResidence
