import React from 'react'
import Response from './Response'
import Redirect from './Redirect'

const FormLienDeParente = ({
    resp,
    user,
    addLinkParente, responseIs, commune, numPage, numTimbre, nameOfficer, nameCommuneOfficer,
    sexePreneur, namePreneur, placeofBirthPreneur, dateBornPreneur, professionPreneur,
    nameFatherPreneur, nameMotherPreneur, placeOfResidenceParentPreneur, AvenueParentPreneur,
    numParentPreneur, quartierParentPreneur, communeParentPreneur, positionPreneur, positionFamillialePreneur,
    sexeEtudiant, nameEtudiant, dateBornEtudiant, placeOfBirthEtudiant, professionEtudiant, avenuEtudiant, numeroEtudiant, quartierEtudiant,
    communeEtudiant, nameFatherEtudiant, nameMotherEtudiant, madeIn, dateMadeIn,
    setCommune, setNumPage, setNumTimbre, setNameOfficer, setNameCommuneOfficer,
    setSexePreneur, setNamePreneur, setPlaceOfBirthPreneur, setDateBornPreneur, setProfessionPreneur,
    setNameFatherPreneur, setNameMotherPreneur, setPlaceOfResidenceParentPreneur, setAvenueParentPreneur,
    setNumParentPreneur, setQuartierParentPreneur, setCommuneParentPreneur, setPositionPreneur,
    setPositionFamillialePreneur, setSexeEtudiant, setNameEtudiant, setDateBornEtudiant, setPlaceOfBirthEtudiant,
    setProfessionEtudiant, setAvenuEtudiant, setNumeroEtudiant, setQuartierEtudiant, setCommuneEtudiant,
    setNameFatherEtudiant, setNameMotherEtudiant, setMadeIn, setDateMadeIn
}) => {

    if (!user) {
        return <Redirect />
    }


     // Fonction pour convertir la Date au Format francais 
     const convertDateFormat = (dateStr) => {
        if (!dateStr) return '';
        const [year, month, day] = dateStr.split('-');
        return `${day}-${month}-${year}`;
      };


    return (
        <div className="parente">
            <div className="parente__container container">
                <h2 className="parente__title">Formulaire de lien de parente</h2>
                <div className="parente__content">
                    Veuillez remplir tous les champs qui se trouve dans ce formulaire afin de creer  <strong>L'Attestion de de lien de parenté</strong>, pour votre client.
                </div>
                <p className="parente__content">
                    Notez bien , le <span>QRCODE sera generer automatiquement une fois vous envoyer le formulaire</span>
                </p>
                        {
                    resp &&  <div className="pop">
                        <div className="pop__container">
                            Formulaire bien soumis, Felication.
                        </div>
                    </div>
                    }
                <form onSubmit={addLinkParente} className="parente__form">
                    <h2 className="parente__title__sub">Formulaire</h2>
                    <div className="parente__top__ville">
                        Commune de <input type="text" value={commune} onChange={(e) => setCommune(e.target.value)} placeholder='Nom de la Commune' className="parente__input" />
                        Numero de la page P.No : <input type="text" value={numPage} onChange={(e) => setNumPage(e.target.value)} placeholder='Numéro de la page' className="parente__input" />
                        Numero vignette T.No : <input type="text" value={numTimbre} onChange={(e) => setNumTimbre(e.target.value)} placeholder='Numéro  de la vignette' className="parente__input" />
                    </div>
                    <p className="parente__spa">
                        Je soussigné, <input type="text" value={nameOfficer} onChange={(e) => setNameOfficer(e.target.value)} placeholder="Nom complet de l'Officier" className="parent__input" />
                        Officier de l'État Civil de la Commune de <input type="text" value={nameCommuneOfficer} onChange={(e) => setNameCommuneOfficer(e.target.value)} placeholder="Nom de la Commune" className="parent__input" /> à Kinshasa,
                    </p>
                    <p className="parente__spa">
                        atteste par la présente que   <select type="text" value={sexePreneur} onChange={(e) => setSexePreneur(e.target.value)} className="parent__input" >
                            <option value='Monsieur'>Monsieur</option>
                            <option value='Madame'>Madame</option>
                        </select>
                        <input type="text" value={namePreneur} onChange={(e) => setNamePreneur(e.target.value)} placeholder="Nom complet du preneur en charge" className="parent__input" /> né (e) à
                        <input type="text" value={placeofBirthPreneur} onChange={(e) => setPlaceOfBirthPreneur(e.target.value)} placeholder="Nom de la ville, village ou province" className="parent__input" /> le
                        <input type="date" value={convertDateFormat(dateBornPreneur)} onChange={(e) => setDateBornPreneur(convertDateFormat(e.target.value))} placeholder="Date de naissance du preneur en charge" className="parent__input" />, de profession
                        <input type="text" value={professionPreneur} onChange={(e) => setProfessionPreneur(e.target.value)} placeholder="Nom de la profession du preneur en charge" className="parent__input" />,
                    </p>
                    <p className="parente__spa">
                        de nationalité Congolaise, fils (fille) de  <input type="text" value={nameFatherPreneur} onChange={(e) => setNameFatherPreneur(e.target.value)} placeholder="Nom complet du Père du preneur en charge" className="parent__input" /> et de
                        <input type="text" value={nameMotherPreneur} onChange={(e) => setNameMotherPreneur(e.target.value)} placeholder="Nom complet de la Mère du preneur en charge" className="parent__input" />, résidant à
                        <input type="text" value={placeOfResidenceParentPreneur} onChange={(e) => setPlaceOfResidenceParentPreneur(e.target.value)} placeholder="Nom de la ville ou village parent" className="parent__input" /> sur l'avenue
                    </p>
                    <p className="parente__spa">
                        <input type="text" value={AvenueParentPreneur} onChange={(e) => setAvenueParentPreneur(e.target.value)} placeholder="Nom de l'avenue parent" className="parent__input" /> n°
                        <input type="text" value={numParentPreneur} onChange={(e) => setNumParentPreneur(e.target.value)} placeholder="Numero de l'avenue parent" className="parent__input" />,
                        quartier  <input type="text" value={quartierParentPreneur} onChange={(e) => setQuartierParentPreneur(e.target.value)} placeholder="Nom du quartier parent" className="parent__input" /> dans la commune de
                        <input type="text" value={communeParentPreneur} onChange={(e) => setCommuneParentPreneur(e.target.value)} placeholder="Nom de la Commune parent" className="parent__input" />, est
                        <select type="text" value={positionPreneur} onChange={(e) => setPositionPreneur(e.target.value)} className="parent__input" >
                            <option value=''>Selectionnez une option</option>
                            <option value='Neveu'>Neveu</option>
                            <option value='Nièce'>Nièce</option>
                            <option value='Frère'>Frère</option>
                            <option value='Soeur'>Soeur</option>
                            <option value='Oncle'>Oncle</option>
                            <option value='Tante'>Tante</option>
                            <option value='Père'>Père</option>
                            <option value='Mère'>Mère</option>
                        </select>
                    </p>
                    <p className="parente__spa">
                        <select type="text" value={positionFamillialePreneur} onChange={(e) => setPositionFamillialePreneur(e.target.value)} className="parent__input" >
                            <option value='Paternel'>Paternel(le)</option>
                            <option value='Maternelle'>Maternel(le)</option>
                        </select>
                        de
                        <select type="text" value={sexeEtudiant} onChange={(e) => setSexeEtudiant(e.target.value)} className="parent__input" >
                            <option value='Monsieur'>Monsieur</option>
                            <option value='Madame'>Madame</option>
                        </select>
                        <input type="text" value={nameEtudiant} onChange={(e) => setNameEtudiant(e.target.value)} placeholder="Nom complet de l'etudiant" className="parent__input" /> né (e) le
                        <input type="date" value={convertDateFormat(dateBornEtudiant)} onChange={(e) => setDateBornEtudiant(convertDateFormat(e.target.value))} placeholder="Date de naissance de l'etudiant" className="parent__input" /> à
                        <input type="text" value={placeOfBirthEtudiant} onChange={(e) => setPlaceOfBirthEtudiant(e.target.value)} placeholder="Nom de la ville etudiant" className="parent__input" />, de profession
                        <input type="text" value={professionEtudiant} onChange={(e) => setProfessionEtudiant(e.target.value)} placeholder="Nom  de sa profession etudiant" className="parent__input" />, de nationalité Congolaise, résidant à Kinshasa sur l'avenue
                        <input type="text" value={avenuEtudiant} onChange={(e) => setAvenuEtudiant(e.target.value)} placeholder="Nom de l'avenue de l'etudiant" className="parent__input" /> n°
                        <input type="text" value={numeroEtudiant} onChange={(e) => setNumeroEtudiant(e.target.value)} placeholder="Numero de l'avenue de l'etudiant" className="parent__input" />,
                    </p>
                    <p className="parente__spa">
                        quartier   <input type="text" value={quartierEtudiant} onChange={(e) => setQuartierEtudiant(e.target.value)} placeholder="Nom du quartier de l'etudiant" className="parent__input" />
                        dans la commune de  <input type="text" value={communeEtudiant} onChange={(e) => setCommuneEtudiant(e.target.value)} placeholder="Nom de la Commune de l'etudiant" className="parent__input" />,
                        fils(fille) de <input type="text" value={nameFatherEtudiant} onChange={(e) => setNameFatherEtudiant(e.target.value)} placeholder="Nom complet du Père de l'etudiant" className="parent__input" /> et de
                        <input type="text" value={nameMotherEtudiant} onChange={(e) => setNameMotherEtudiant(e.target.value)} placeholder="Nom complet de la Mère de l'etudiant" className="parent__input" />
                    </p>
                    <br />
                    <p className="parente__spa">
                        Fait à <input type="text" value={madeIn} onChange={(e) => setMadeIn(e.target.value)} placeholder="Nom de la ville" className="parent__input" />,
                        le <input type="date" value={convertDateFormat(dateMadeIn)} onChange={(e) => setDateMadeIn(convertDateFormat(e.target.value))} placeholder="Date du jour" className="parent__input" />
                    </p>

                    <div className="parente__btns">
                        <button type='submit' className="parente__btn">Soumettre</button>
                        <button type='reset' className="parente__btn">Annuler</button></div>
                </form>
            </div>
        </div>
    )
}

export default FormLienDeParente
