import axios from "axios"


const apiLogin = '/api/login'

const login = async credentials => {
    const response = await axios.post(apiLogin, credentials)
    return response.data
}



export default { login }