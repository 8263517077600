import React from 'react'
import imgHero from '../Assets/hero-img.png'
import logoHero from '../Assets/logoHVK.png'
import Banner from './Banner'
import Footer from './Footer'

const Home = () => {
  return (
    <div className="home">
        <div className="hero">
        <div className="home__container container">
            <div className="home__elements">
                <div className="home__logo">
                    <img src={logoHero} alt="" className="home__logo__img" />
                </div>
                <h2 className="home__title">Faites vos demandes en ligne</h2>
                <div className="home__content">
                Retrouvez toutes les informations nécessaires pour aborder sereinement vos démarches d’état-civil et d'autres services communaux
                </div>
                <button className="home__button">Commencez</button>
            </div>
            <div className="home__elements">
                <img src={imgHero} alt="" className="home__image" />
            </div>
           </div>
        </div>
        <Banner />
        <Footer />
    </div>
  )
}

export default Home
