import React from 'react'
import { tailChase } from 'ldrs'

tailChase.register()


const Loading = () => {
  return (
    <div className="loading">
        <div className="loading__content">
            <l-tail-chase
            size="40"
            speed="1.75" 
            color="#13476e" 
            ></l-tail-chase>
        </div>
    </div>
  )
}

export default Loading
