import React from 'react'
import { Link } from 'react-router-dom'

const ListForm = () => {
  return (
    <div className="listform">
        <div className="listform__container container">
            <h2 className="listform__title">Liste des formulaires</h2>
            <p className="listform__content">
                Voici la list de formulaire , dont vous avait besoin
            </p>
          <ol className="listform__list">
            <li className="listform__item">
                <Link to='/formulaire-de-lien-parente' className="listform__link">Formulaire de lien de Parenté </Link>
            </li>
            <li className="listform__item">
                <Link to='/attestation-de-residence' className="listform__link">Formulaire d'Attestation de Residence </Link>
            </li>
          </ol>
        </div>
    </div>
  )
}

export default ListForm
