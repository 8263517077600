import React, { useState } from 'react'
import Response from './Response'
import Redirect from './Redirect'
import { Link } from 'react-router-dom'

const FormAttestationDeResidence = ({
    residDateIdentifie, setResidDateIdentifie,
    residCityIdentifie, setResidCityIdentifie,
    resp,
    addResidence,
    residCityMadeIn, setResidCityMadeIn,
    linkResidence,setLinkResidence, setResidCommune, setResidNumPage, setResidNumTimbre,
    setNameBourgmestre, setResidNamesEtudiant, setResidCityBirthEtudiant,
    setResidDateBirthEtudiant,setResidPereEtudiant,setResidMereEtudaint,setResidFemmeEtudiant,
    setResidAvenuEtudiant,setNumEtudiant,setResidQuartierEtudiant,setResidCommuneEtudiant,setResidProfession,setResidDateMadeIn,setNumDossier,
    residCommune,residNumPage,residNumTimbre,nameBourgmestre,
    residNamesEtudiant,residCityBirthEtudiant,residDateBirthEtudiant,
    residPereEtudiant,residMereEtdudiant,residFemmeEtudiant,
    residAvenuEtudiant,residNumEtudiant,residQuartierEtudiant,
    residCommuneEtudiant,residProfession,residDateMadeIn,residNumDossier }) => {


        // Fonction pour convertir la Date au Format francais 
        // const convertDateFormat = (dateStr) => {
        //     if (!dateStr) return '';
        //     const [year, month, day] = dateStr.split('-');
        //     return `${day}-${month}-${year}`;
        //   };

        const convertDateFormat = (dateStr) => {
            if (!dateStr || typeof dateStr !== 'string') return '';
            try {
              const [year, month, day] = dateStr.split('-');
              return `${day}-${month}-${year}`;
            } catch (error) {
              console.error('Erreur lors de la conversion de la date:', error);
              return dateStr; // ou retournez une valeur par défaut
            }
          };
          
          

    return (
      <div className="parente formResidence">   
          <div className="formResidence__container container">
             {
             resp &&  <div className="pop">
                 <div className="pop__container">
                     Formulaire bien soumis, Felication.
                 </div>
              </div>
              }
              <div className="formResidence__card__links">
                  <Link to='/forms' className="formResidence__card__link">Retour en arrière</Link>
                  <div className="formResidence__card__link formResidence__bar"> - </div>
                  <Link className="formResidence__card__link">Attestation de Résidence</Link>
              </div>
               <h2 className="formResidence__title">Formulaire d'Attestation de Résidence</h2>
               <div className="formResidence__banner">
               <form onSubmit={addResidence} className="parente__form">
                    <h2 className="parente__title__sub">Formulaire</h2>
                    <div className="parente__top__ville">
                        Commune de <input type="text" value={residCommune} onChange={(e) => setResidCommune(e.target.value)} placeholder='Nom de la Commune' className="parente__input" />
                        Numero de la page P.No : <input type="text" value={residNumPage} onChange={(e) => setResidNumPage(e.target.value)} placeholder='Numéro de la page' className="parente__input" />
                        Numero vignette T.No : <input type="text" value={residNumTimbre} onChange={(e) => setResidNumTimbre(e.target.value)} placeholder='Numéro  de la vignette' className="parente__input" />
                    </div>
                    <p className="parente__spa">
                        Je soussigné, <input type="text" value={nameBourgmestre} onChange={(e) => setNameBourgmestre(e.target.value)} placeholder="Nom complet de l'Officier" className="parent__input" />
                        Bourgmestre de la Commune de <input type="text" value={residCommune} onChange={(e) => setResidCommune(e.target.value)} placeholder="Nom de la Commune" className="parent__input" /> ,
                    </p>
                    <p className="parente__spa">
                        atteste par la présente que   <input type="text" value={residNamesEtudiant} onChange={(e) => setResidNamesEtudiant(e.target.value)} placeholder="Nom de l'etudiant" className="parent__input" /> né(e) à
                        <input type="text" value={residCityBirthEtudiant} onChange={(e) => setResidCityBirthEtudiant(e.target.value)}  placeholder="Nom de la ville de naissance pour etudiant" className="parent__input" /> le
                        <input type="date" value={convertDateFormat(residDateBirthEtudiant)} onChange={(e) => setResidDateBirthEtudiant(convertDateFormat(e.target.value))} placeholder="Date de naissance de l'etudiant" className="parent__input" />, fille (fils) de
                        <input type="text" value={residPereEtudiant} onChange={(e) => setResidPereEtudiant(e.target.value)} placeholder="Nom du Pere de l'etudiant" className="parent__input" /> et de 
                        <input type="text" value={residMereEtdudiant} onChange={(e) => setResidMereEtudaint(e.target.value)} placeholder="Nom de la Mere de l'etudiant" className="parent__input" />,
                    </p>
                    <p className="parente__spa">
                        est marié   <input type="text" value={residFemmeEtudiant} onChange={(e) => setResidFemmeEtudiant(e.target.value)}  placeholder="Nom de l'epouse de l'etudiant" className="parent__input" /> , résidant sur l'avenue
                        <input type="text" value={residAvenuEtudiant} onChange={(e) =>  setResidAvenuEtudiant(e.target.value)} placeholder="Nom de l'avenue de l'etudiant" className="parent__input" />  n° 
                        <input type="text" value={residNumEtudiant} onChange={(e) => setNumEtudiant(e.target.value)} placeholder="Numero de l'avenue de l'etudiant" className="parent__input" />,
                    </p>
                    <p className="parente__spa">
                       dans le quartier  <input type="text" value={residQuartierEtudiant} onChange={(e) => setResidQuartierEtudiant(e.target.value)} placeholder="Nom du quartier etudiant" className="parent__input" /> dans la commune de
                        <input type="text" value={residCommuneEtudiant} onChange={(e) => setResidCommuneEtudiant(e.target.value)}  placeholder="Nom de la Commune de letudiant" className="parent__input" />, de nationalité <span> Congolaise</span>, 
                        de profession <input type="text" value={residProfession} onChange={(e) => setResidProfession(e.target.value)} placeholder="Fonction de l'etudiant" className="parent__input" />,  a été identifié(e) dans la commune de
                        <input type="text" value={residCommune} onChange={(e) => setResidCommune(e.target.value)} placeholder="Nom de la commune" className="parent__input" /> à 
                        <input type="text" value={residCityIdentifie} onChange={(e) => setResidCityIdentifie(e.target.value)} placeholder="Nom de la ville identifier" className="parent__input" />, le 
                        <input type="date" value={convertDateFormat(residDateIdentifie)} onChange={(e) => setResidDateIdentifie(convertDateFormat(e.target.value))} placeholder="Date du jour" className="parent__input" />, sous le N° 
                        <input type="text" value={residNumDossier} onChange={(e) => setNumDossier(e.target.value)} placeholder="Le N°17/00214"  className="parent__input" />
                    </p>
                    <br />
                    <p className="parente__spa">
                        Fait à <input type="text" value={residCityMadeIn} onChange={(e) => setResidCityMadeIn(e.target.value)} placeholder="Fait a Kinshasa" className="parent__input" />,
                        le <input type="date" value={convertDateFormat(residDateMadeIn)} onChange={(e) => setResidDateMadeIn(convertDateFormat(e.target.value))} placeholder="Date du jour" className="parent__input" />
                    </p>

                    <div className="parente__btns">
                        <button type='submit' className="parente__btn">Soumettre</button>
                        <button type='reset' className="parente__btn">Annuler</button></div>
                </form>
               </div>
          </div>
      </div>
  )
}

export default FormAttestationDeResidence
